/*

Breakpoints in PX

xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px
  
}*/

@font-face {
  font-family: muli, "sans-serif";
  src: url(https://use.typekit.net/aav7lhy.css);
  font-weight: 400;
  font-style: italic;
} 

@font-face {
  src: url(https://use.typekit.net/aav7lhy.css);
  font-family: muli,sans-serif;
  font-weight: 400;
  font-style: normal;
} 

@font-face {
  src: url(https://use.typekit.net/aav7lhy.css);
  font-family: muli,sans-serif;
  font-weight: 700;
  font-style: italic;
} 

@font-face {
  src: url(https://use.typekit.net/aav7lhy.css);
  font-family: muli,sans-serif;
  font-weight: 700;
  font-style: bold;
} 


html,
body {
  --colourPop: #e51a94; /* var(--colourPop); */
  --colourTextGrey: #3e4a56; /* var(--colourTextGrey); */
  --colourTextDark: #1f306d; /* var(--colourTextDark); */
  --colourBackLilac: #e0e8fa; /* var(--colourBackLilac); */
  --colourBackGrey: #f5f7fd; /* var(--colourBackGrey); */
  --colourBackWhite: #ffffff; /* var(--colourBackWhite); */
  margin: 0;
  /* overflow: hidden; */
  background-repeat: repeat;
  font-family: muli !important;
  color: var(--colourTextGrey);
  max-width: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;                                                                                 
  padding: 0;
  /* overflow: hidden; */
  /* font-family: PT-Sans; */
  /* font-family: "okta-neue", sans-serif; */
}

.home,
.about,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

#particles-background {
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.95;
  background-color: var(--colourBackLilac);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.body-particles {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--colourBackLilac);
}

.aboutBackground {
  background-color: rgba(188, 15, 15, 0.56);
  height: 100vh;
  width: 100%;
}
