.formContainer {
  height: fit-content;
  width: 50%;
  max-width: 600px;
  border-radius: 20px;
  font-size: 50px;
  color: var(--colourPop);
  margin-top: 100px;
  margin-bottom: 100px;
}

.contactUs{
  color: var(--colourBackWhite) !important;
  font-size: 3rem !important;
  min-height: fit-content;
} 



.formContainer h1 {
  padding-bottom: 20px;
  padding-top: 8%;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 25px;
  color: var(--colourBackWhite);
}

.input {
  height: 50px;
  width: 85%;
  border-radius: 5px;
  font-size: 20px;
  padding-left: 20px;
  color: var(--colourPop);
  text-decoration: none !important;
  outline: none !important;
  border-width:0px;
  border:none;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
}

.formItem {
  grid: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.textArea {
  font-size: 20px;
  width: 85%;
  height: 100px;
  max-height: 150px;
  border-radius: 5px;
  padding-left: 20px;
  padding-top: 10px;
  color: var(--colourPop);
  outline: none !important;
  border-width:0px;
  border:none;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
}


@media screen and (max-width: 700px) {



  .formContainer {
    width: 70%;
    /* min-height: 10000px; */
    min-height: fit-content;
    padding-bottom: 40px;
  }

  .contactUs{
    color: var(--colourPop) !important;
  } 
  

}

.submitButton {
  color: var(--colourBackWhite);
  font-size: 20px;
  background: var(--colourPop);
  padding: 8px 20px;
  border: 1px solid var(--colourPop);
  border-radius: 20px;
  margin-bottom: 20px;
  outline: none;
}

.submitButton:hover {
  color: var(--colourPop);
  font-size: 20px;
  background: var(--colourBackLilac);
  padding: 8px 20px;
  border: 1px solid var(--colourPop);
  border-radius: 20px;
}

.submitButton:active {
  outline: none;
}

textarea:focus,
input:focus {
  outline: none;
}

